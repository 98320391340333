
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';

import TheHeader from '@/components/TheHeader.vue';
// import TheError from '@/components/TheError.vue';
import ServersTable from '@/components/ServersTable.vue';
import UpdateTime from '@/components/UpdateTime.vue';
// import ServersCard from '@/components/ServersCard.vue';
import TheFooter from '@/components/TheFooter.vue';
import { BoxItem, StatusItem } from '@/types';

export default defineComponent({
  name: 'App',
  components: {
    TheHeader,
    // TheError,
    ServersTable,
    // ServersCard,
    UpdateTime,
    TheFooter,
  },
  setup() {
    const servers = ref<Array<StatusItem | BoxItem>>();
    const updated = ref<number>();
    const { interval } = window.__PRE_CONFIG__;
    let timer: number;
    const runFetch = () =>
      axios
        .get('json/stats.json')
        .then((res) => {
          const serverData = res.data.servers;
          let totalServersCount = serverData.length;
          const totalServer = {
            name: '总览',
            type: 'KVM',
            host: 'None',
            location: 'N/A',
            online4: true,
            online6: false,
            uptime: 'N/A',
            load: 0,
            network_rx: 0,
            network_tx: 0,
            network_in: 0,
            network_out: 0,
            cpu: 0,
            memory_total: 0,
            memory_used: 0,
            swap_total: 0,
            swap_used: 0,
            hdd_total: 0,
            hdd_used: 0,
            custom: '',
            region: 'CN',
          };

          for (const server of serverData) {
            if (server.network_rx === undefined) {
              // 如果服务器没有提供特定数据，就跳过它并减少服务器总数
              totalServersCount--;
              continue;
            }
            // 累加数据前先检查属性是否存在
            totalServer.load += server.load || 0;
            totalServer.network_rx += server.network_rx || 0;
            totalServer.network_tx += server.network_tx || 0;
            totalServer.network_in += server.network_in || 0;
            totalServer.network_out += server.network_out || 0;
            totalServer.cpu += server.cpu || 0;
            totalServer.memory_total += server.memory_total || 0;
            totalServer.memory_used += server.memory_used || 0;
            totalServer.swap_total += server.swap_total || 0;
            totalServer.swap_used += server.swap_used || 0;
            totalServer.hdd_total += server.hdd_total || 0;
            totalServer.hdd_used += server.hdd_used || 0;
          }

          if (totalServersCount > 0) {
            // 确保不会除以零
            totalServer.cpu = parseFloat((totalServer.cpu / totalServersCount).toFixed(1));
            totalServer.load = parseFloat((totalServer.load / totalServersCount).toFixed(1));
          }

          serverData.unshift(totalServer);
          servers.value = serverData;
          updated.value = Number(res.data.updated);
        })
        .catch((err) => console.log(err));

    onMounted(
      () =>
        (runFetch(), true) && (timer = setInterval(runFetch, interval * 1000))
    );
    onBeforeUnmount(() => clearInterval(timer));
    return {
      servers,
      updated,
    };
  },
});
